/* src/components/MemoList.css */

.gemini {
    max-width: 832px;
    list-style: none;
    margin: 0 auto;
}

.gemini-response {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    word-wrap: break-word;
    overflow: hidden;
}

/* Add media queries for smaller screens */
@media (max-width: 600px) {
    .card {
        width: 100%;
        padding: 12px;
        margin-bottom: 12px;
    }

}