/* src/components/MemoList.css */

/* 800 + 16 * 2 */
.memo-list {
    max-width: 832px;
    list-style: none;
}

.card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    word-wrap: break-word;
    overflow: hidden;
}

.card-content {
    display: flex;
    flex-direction: column;
}

.card-header h3 {
    margin: 0 0 8px 0;
    font-size: 1.2em;
}

.card-body {
    flex-grow: 1;
}

.card-body p {
    margin: 8px 0;
}

.card-body a {
    color: #007BFF;
    text-decoration: none;
}

.card-body a:hover {
    text-decoration: underline;
}

.tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.tag {
    background-color: #e0e0e0;
    border-radius: 12px;
    padding: 2px 8px;
    font-size: 0.875em;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s;
}

.tag:hover {
    background-color: #ccc;
}

.card-footer {
    margin-top: 16px;
}

.card-footer button {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.card-footer button:hover {
    background-color: #0056b3;
}

.load-more-button {
    display: block;
    width: 100%;
    margin-top: 16px;
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.load-more-button:hover {
    background-color: #0056b3;
}

/* Add media queries for smaller screens */
@media (max-width: 600px) {
    .card {
        width: 100%;
        padding: 12px;
        margin-bottom: 12px;
    }

    .card-header h3 {
        font-size: 1em;
    }

    .card-body p {
        margin: 4px 0;
    }

    .card-footer button {
        padding: 6px 12px;
    }
}