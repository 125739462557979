/* src/components/AddMemo.css */

form {
    max-width: 800px;
    margin: 2em auto;
    padding: 1em;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

form input,
form textarea,
form button {
    width: 100%;
    margin-bottom: 1em;
    padding: 0.5em;
    border: 1px solid #ccc;
    border-radius: 3px;
}

form button {
    background-color: #28a745;
    color: white;
    border: none;
    cursor: pointer;
}

form button:hover {
    background-color: #218838;
}

.tags-input {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
}

.tags-input input {
    flex-grow: 1;
    margin-right: 0.5em;
}

.tags-list {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    /* Reduced gap between tags */
    margin: 0;
    /* Remove default margin */
    padding: 0;
    /* Remove default padding */
}

.tag-item {
    background-color: #e0e0e0;
    border-radius: 12px;
    padding: 2px 8px;
    /* Reduced padding for a more compact look */
    font-size: 0.875em;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s;
}

.tag-item:hover {
    background-color: #ccc;
}