/* src/components/SearchMemo.css */

.search-tags {
    max-width: 800px;
    margin: 2em auto;
    padding: 1em;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.search-tags select {
    width: 100%;
    margin-bottom: 1em;
    padding: 0.5em;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.search-tags select:focus {
    outline: none;
    border-color: #28a745;
    box-shadow: 0 0 5px rgba(40, 167, 69, 0.5);
}

.tags-list {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    margin: 0;
    padding: 0;
}

.tag-item {
    background-color: #e0e0e0;
    border-radius: 12px;
    padding: 2px 8px;
    font-size: 0.875em;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s;
}

.tag-item:hover {
    background-color: #ccc;
}