/* src/styles.css */

body {
    font-family: Arial, sans-serif;
    background-color: #f0f0f0;
    margin: 0;
    padding: 0;
}

nav {
    background-color: #333;
    padding: 1em;
}

nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
}

nav li {
    margin: 0 1em;
}

nav a {
    color: gray;
    text-decoration: none;
    font-weight: bold;
}

nav a:hover {
    text-decoration: underline;
}

h1 {
    color: #333;
    text-align: center;
}

form {
    max-width: 800px;
    margin: 2em auto;
    padding: 1em;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

form input,
form textarea,
form button {
    width: 100%;
    margin-bottom: 1em;
    padding: 0.5em;
    border: 1px solid #ccc;
    border-radius: 3px;
}

form button {
    background-color: #28a745;
    color: white;
    border: none;
    cursor: pointer;
}

form button:hover {
    background-color: #218838;
}

ul {
    max-width: 600px;
    margin: 2em auto;
    padding: 0;
    list-style-type: none;
}

ul li {
    background-color: white;
    margin-bottom: 1em;
    padding: 1em;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

ul button {
    background-color: #dc3545;
    color: white;
    border: none;
    cursor: pointer;
    padding: 0.5em 1em;
    border-radius: 3px;
}

ul button:hover {
    background-color: #c82333;
}